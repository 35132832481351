<template>
  <cks-page-wrap v-loading="loading" style="padding-bottom: 100px;">
    <cks-edit-form
      @updatePosi="updatePosi"
      :isPosi="true"
      title="冷链资产运营编辑"
      @save="save"
      ref="formRef"
      label-position="top"
      :rules="rules"
      :model="form"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="标题图片(建议图片大小210px * 190px, 不应超过1M大小)" prop="picture">
            <cks-upload v-model="form.picture" @updateUrl="updateUrl" :fileUrl="form.picture" :limit="1"></cks-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="轮播图片(建议图片大小210px * 190px, 不应超过1M大小)" prop="bannerPicture">
            <cks-upload v-model="form.bannerPicture" @updateUrl="updateUrl2" :fileUrl="form.bannerPicture" :limit="4"></cks-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="园区标题" prop="parkTitle">
            <el-input v-model="form.parkTitle"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="园区地址" prop="parkAddress">
            <el-input v-model="form.parkAddress"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所属城市" prop="city">
            <cks-select v-model="form.city" :options="cityList"></cks-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话" prop="contact">
            <el-input v-model="form.contact"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="园区描述" prop="parkDesc">
            <el-input v-model="form.parkDesc"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="园区概况" prop="parkSituation">
            <el-input type="textarea" rows="5" v-model="form.parkSituation"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <h3 class="title">服务内容</h3>
        </el-col>
        <el-col :span="24">
          <el-checkbox-group v-model="form.checkList">
            <el-checkbox :label="item.text" :value="item.text" size="large" border v-for="item in content" :key="item.key" />
          </el-checkbox-group>
        </el-col>
        <el-col :span="24">
          <h3 class="title">建造及设施标准</h3>
        </el-col>
        <el-col :span="6">
          <el-form-item label="占地面积" prop="floorArea">
            <el-input v-model="form.floorArea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="建筑面积" prop="buildingArea">
            <el-input v-model="form.buildingArea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="可租赁面积" prop="rentalArea">
            <el-input v-model="form.rentalArea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="建筑结构" prop="buildingStructure">
            <el-input v-model="form.buildingStructure"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="库内净高" prop="insideHeight">
            <el-input v-model="form.insideHeight"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="库内地面" prop="insideGround">
            <el-input v-model="form.insideGround"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="库温" prop="insideTemperature">
            <el-input v-model="form.insideTemperature"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="停车位" prop="parkingSpace">
            <el-input v-model="form.parkingSpace"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="月台形式" prop="platformFormat">
            <el-input v-model="form.platformFormat"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地面荷载" prop="groundLoad">
            <el-input v-model="form.groundLoad"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="园区配套" prop="parkFacility">
            <el-input v-model="form.parkFacility"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="升降平台" prop="liftPlatform">
            <el-input v-model="form.liftPlatform"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="耐火等级" prop="fireRating">
            <el-input v-model="form.fireRating"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="储物类别" prop="storageCategory">
            <el-input v-model="form.storageCategory"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="消防系统" prop="fireProtectionSystem">
            <el-input v-model="form.fireProtectionSystem"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="照明系统" prop="lightingSystem">
            <el-input v-model="form.lightingSystem"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="是否有雨棚" prop="havingCanopy">
            <el-input v-model="form.havingCanopy"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="物业服务" prop="propertyServices">
            <el-input v-model="form.propertyServices"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="滑升门&升降台" prop="slidingAndLifting">
            <el-input v-model="form.slidingAndLifting"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="备用发动机&通风系统" prop="generatorAndVentilation">
            <el-input v-model="form.generatorAndVentilation"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="室外周转场地宽" prop="outdoorWidth">
            <el-input v-model="form.outdoorWidth"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="电力设备" prop="powerEquipment">
            <el-input v-model="form.powerEquipment"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="压缩机组" prop="compressorUnit">
            <el-input v-model="form.compressorUnit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </cks-edit-form>
  </cks-page-wrap>
</template>

<script>
import {
  toRefs, reactive, watchEffect,
} from 'vue';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { getRequireRule, showSuccess } from '@/utils';
import apis from '@/request/apis';
import router from '@/router';
import { Search } from '@element-plus/icons-vue';

export default {
  props: ['id'],
  setup(props) {
    const data = reactive({
      Search,
      content: [
        {
          key: '1',
          text: '库内服务',
        },
        {
          key: '2',
          text: '增值服务',
        },
        {
          key: '3',
          text: '运输服务',
        },
        {
          key: '4',
          text: '一件代发',
        },
      ],
      formRef: null,
      form: {
        picture: '',
        bannerPicture: '',
        checkList: [],
        parkTitle: '',
        parkAddress: '',
        contact: '0755-82263776',
        parkSituation: '',
        standard: true,
      },
      rules: {
        picture: [getRequireRule('标题图片')],
        bannerPicture: [getRequireRule('轮播图片')],
        parkTitle: [getRequireRule('园区标题')],
        parkAddress: [getRequireRule('园区地址')],
        parkSituation: [getRequireRule('园区概况')],
        contact: [getRequireRule('联系电话')],
        city: [getRequireRule('所属城市')],
      },
      loading: false,
      cityList: [],
    });

    async function save(done) {
      try { await data.formRef.validate(); } catch (e) { return done(false); }
      try {
        await apis.operate.save({
          ...data.form,
          serviceContent: data.form.checkList.join(','),
        });
        showSuccess('保存成功');
        router.push({
          name: 'operateCom',
        });
      } catch (e) {
        done(e);
      }
    }
    const updateUrl = (val) => {
      data.form.picture = val;
    };
    const updateUrl2 = (val) => {
      data.form.bannerPicture = val;
    };

    const detail = async () => {
      const res = await apis.operate.getDetail({
        id: props.id,
      });
      data.form = res;
      data.form.checkList = res.serviceContent?.split?.(',') || [];
    };

    watchEffect(async () => {
      if (props.id) {
        await detail();
      }
    });

    const config = async () => {
      const res = await apis.operate.config();
      data.cityList = res;
    };
    config();

    const preview = () => {
      router.push({
        name: 'operate-edit',
      });
    };
    return {
      save,
      ...toRefs(data),
      updateUrl,
      updateUrl2,
      preview,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .quill-editor {
  height: 400px;
}
::v-deep .ql-container{
  height: 300px;
}
.posi {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20px;
  z-index: 999;
  background-color: #f5f7fa;
  opacity: 0.5;
  cursor:not-allowed;
}
.el-input {
  --el-input-focus-border: #2F51FF;
}
::v-deep.active-img-list .el-upload {
  display: none;
}
::v-deep .el-textarea__inner:focus {
  border: 1px solid #2F51FF;;
}
.title {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
}
.posi-btn {
  position: fixed;
  bottom: 50px;
  right: 150px;
}
</style>
